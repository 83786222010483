import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonVariant,
  Icon,
  Input,
  InputSize,
  InputVariant,
} from '@hubportal/components';
import {
  filterByProperties,
  getTimeNow,
  sortRidersByState,
} from 'utils/helpers';
import { CountByStatus } from './components/count';
import { RiderTile } from './components/tile';
import { getRidersList } from 'utils/network/apis';
import {
  RiderStateMappings,
  RiderStates,
  UNDERAGE_RESTRICTED_START_TIME,
  UNDERAGE_WARNING_START_TIME,
} from 'utils/constants';
import { Events, trigger } from 'utils/events';
import usePolling from 'utils/hooks/usePolling';
import useMountedState from 'utils/hooks/useMountedState';
import { Employee } from 'models';

interface RiderParcelProps {
  className?: string;
  hub: string;
  country: string;
  mapEnabled: boolean;
  titleEnabled: boolean;
}

const mapRiderInfo = (riders: Employee[]): RiderBasicInfo[] => {
  return riders
    .filter((rider) => rider.status !== RiderStates.OFFLINE)
    .map((rider) => ({
      id: rider.workforceID,
      status: rider.status,
      statusReason: rider.statusReason,
      firstName: rider.firstName,
      lastName: `${rider.lastName?.trim()[0]}.`,
    }));
};

const underageRiders = (riders): RiderBasicInfo[] => {
  return riders
    .filter(
      (rider: Employee) =>
        rider.status !== RiderStates.OFFLINE && rider.isUnderage()
    )
    .map((rider: Employee) => ({
      id: rider.workforceID,
      status: rider.status,
      firstName: rider.firstName,
      lastName: `${rider.lastName?.trim()[0]}.`,
    }));
};

const RidersParcel = ({
  className = '',
  hub,
  country,
  mapEnabled,
  titleEnabled,
}: RiderParcelProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [riders, setRiders] = useState<Employee[]>([]);
  const isMounted = useMountedState();

  const getRiders = async (): Promise<void> => {
    if (!hub) {
      return;
    }
    const response = await getRidersList(hub, {
      max: 99999,
      skip: 0,
      status: [
        RiderStates.ONLINE,
        RiderStates.RETURNING,
        RiderStates.BUSY,
        RiderStates.TEMP_OFFLINE,
        RiderStates.IDLE,
        RiderStates.ON_BREAK,
        RiderStates.STARTING,
      ],
    });

    if (isMounted()) {
      const ridersMapped = response.results?.map(Employee.from) || [];
      setRiders(ridersMapped);
      trigger(Events.RIDER_STATES, mapRiderInfo(ridersMapped));

      if (country === 'NL') {
        if (getTimeNow() >= UNDERAGE_WARNING_START_TIME) {
          trigger(Events.UNDERAGE_RIDERS, underageRiders(ridersMapped) || []);
        }
      }

      const declining = (ridersMapped || []).filter((r) => r.isDeclining());
      trigger(Events.TEMP_OFFLINE_RIDERS, mapRiderInfo(declining));
    }
  };

  const { error, respawn } = usePolling(async () => await getRiders(), [hub]);
  const filteredRiders = riders
    .filter(
      filterByProperties(searchTerm, [
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
      ])
    )
    .sort((a, b) => {
      if (a.statusUpdatedAt && b.statusUpdatedAt) {
        return (
          new Date(a.statusUpdatedAt).getTime() -
          new Date(b.statusUpdatedAt).getTime()
        );
      } else if (a.statusUpdatedAt) {
        return -1;
      }
      return 1;
    })
    .sort(sortRidersByState);

  const counts = riders.reduce(
    (acc, cur: Employee) => {
      const mappedStatus = RiderStateMappings[cur.status]?.toLowerCase();
      acc[mappedStatus] = Number(acc[mappedStatus]) + 1;
      return acc;
    },
    { online: 0, returning: 0, busy: 0, inactive: 0 }
  );

  const timeNow = getTimeNow();
  return (
    <div
      className={classnames(
        'bg-primary min-h-0 flex flex-col py-6 px-4 text-gray-300',
        className
      )}
    >
      {titleEnabled && (
        <span className="header-m text-white">{t('riders')}</span>
      )}
      <div className="flex justify-between py-2">
        <CountByStatus
          count={counts.online}
          status={t('rider_status_online')}
        />
        <CountByStatus
          count={counts.returning}
          status={t('rider_status_returning')}
        />
        <CountByStatus count={counts.busy} status={t('rider_status_busy')} />
        {counts.inactive > 0 && (
          <CountByStatus
            count={counts.inactive}
            status={t('rider_status_inactive')}
          />
        )}
      </div>
      <div className="mt-2 mb-4 flex justify-between gap-4">
        <Input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={t('search')}
          variant={InputVariant.secondary}
          inputSize={InputSize.small}
        />
        {mapEnabled && (
          <div
            className="flex items-center gap-2 title-s cursor-pointer whitespace-nowrap"
            onClick={() => {
              trigger(Events.VIEW_RIDER_LOCATIONS);
              trigger(Events.RIDER_STATES, mapRiderInfo(riders));
            }}
          >
            <Icon type="mapSearch" />
            {t('open_map')}
          </div>
        )}
      </div>
      <div className="overflow-auto flex-1 min-h-0">
        {error ? (
          <div className="text-s flex flex-col gap-2 items-center">
            <div>{error}</div>
            <Button variant={ButtonVariant.secondary} onClick={() => respawn()}>
              {t('retry')}
            </Button>
          </div>
        ) : (
          filteredRiders.map((rider) => {
            return (
              <RiderTile
                key={`rider-${rider.workforceID}`}
                rider={rider}
                underageFlagVisible={
                  rider?.hubSlug?.startsWith('nl') &&
                  timeNow >= UNDERAGE_RESTRICTED_START_TIME &&
                  rider.isUnderage()
                }
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default RidersParcel;
